<template>
  <div class="express-list-container">
    <van-search
        v-model.trim="searchName"
        shape="round"
        placeholder="请输入搜索关键词"
    />

    <page-container class="express-list-scroller">
      <van-index-bar :index-list="indexList">
        <div
            v-for="expressIndex in queryExpressIndexList"
            :key="expressIndex.letter"
        >
          <van-index-anchor
              :index="expressIndex.letter"
          />
          <van-cell
              v-for="express in expressIndex.data"
              :key="express.id"
              :title="express.name"
              @click="handleExpressClick(express)"
          />
        </div>
      </van-index-bar>
    </page-container>
  </div>
</template>

<script>
import { getExpressList } from '@/api/normalOrder';
import { buildTextData } from '@/util/indexed-list';

export default {
  name: 'ExpressList',
  data() {
    return {
      searchName: '',
      expressList: [],
    };
  },
  computed: {
    nameFilterList() {
      let { searchName, expressList } = this;
      if (!searchName)
        return expressList;
      else
        return expressList.filter(e => e.name.indexOf(searchName) > -1);
    },
    queryExpressIndexList() {
      return buildTextData(this.nameFilterList).filter(e => e.data.length);
    },
    indexList() {
      return this.queryExpressIndexList.map(e => e.letter);
    },
  },
  created() {
    getExpressList().then(res => {
      this.expressList = res || [];
    });
  },
  methods: {
    handleExpressClick(express) {
      let { model } = this.$route.query;

      if (model === 'integral')
        this.$store.commit('integralSendBack/setExpressInfo', express);
      else
        this.$store.commit('sendBack/setExpressInfo', express);

      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.express-list-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .search-btn {
    padding: 0 7px;
    color: @primary-color;
  }

  .express-list-scroller {
    top: @search-padding * 2 + @search-input-height;
  }
}
</style>
